import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import {Item} from 'semantic-ui-react';
import Page from '../components/site/layouts/Page';
import TaxonomyPageTitle from '../components/shared/TaxonomyPageTitle';
import SEO from '../components/shared/SEO/SEO';

const TITLE = 'Experience Austin Texas';
const DESCRIPTION = 'Learn about Austin the great capital city of Texas';
const EXTRA_DATA = {
  buy: {
    imageUrl:
      'https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjEyMDEyMn0',
    authorName: 'Tierra Mallorca',
    authorUrl: 'https://unsplash.com/@tierramallorca',
  },
  sell: {
    imageUrl:
      'https://images.unsplash.com/flagged/photo-1558954157-aa76c0d246c6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjEyMDEyMn0',
    authorName: 'Precondo CA',
    authorUrl: 'https://unsplash.com/@precondo',
  },
  rent: {
    imageUrl:
      'https://images.unsplash.com/photo-1543868170-c24588ae48f8?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjEyMDEyMn0',
    authorName: 'Tomek Baginski',
    authorUrl: 'https://unsplash.com/@tombag',
  },
  improve: {
    imageUrl:
      'https://images.unsplash.com/photo-1556912172-45b7abe8b7e1?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjEyMDEyMn0',
    authorName: 'Creatv Eight',
    authorUrl: 'https://unsplash.com/@creatveight',
  },
  live: {
    imageUrl:
      'https://images.unsplash.com/photo-1542339867-babe283a18ad?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjEyMDEyMn0',
    authorName: 'Ryan Barron',
    authorUrl: 'https://unsplash.com/@netherlandz108',
  },
};

const useData = () => {
  const result = useStaticQuery(
    graphql`
      query articleCategoriesPage {
        ahp {
          findArticleCategories {
            nodes {
              slug
              title
              content
              articleCount
            }
          }
        }
      }
    `,
  );

  const categories = result?.ahp?.findArticleCategories?.nodes;
  if (!categories) {
    return {categories: null};
  }

  return {
    categories: categories
      .map(c => ({...c, ...EXTRA_DATA[c.slug]}))
      .filter(c => c.articleCount > 0)
      .sort((a, b) => {
        if (a.slug === 'buy') return -1;
        if (b.slug === 'buy') return 1;
        if (a.slug === 'sell') return -1;
        if (b.slug === 'sell') return 1;
        if (a.slug === 'rent') return -1;
        if (b.slug === 'rent') return 1;
        return a.slug > b.slug ? 1 : 0;
      }),
  };
};

const ArticleCategoriesPage = () => {
  const {categories} = useData();
  return (
    <Page sidebar="blog">
      <SEO
        title={TITLE}
        description={DESCRIPTION}
        pathname="article-categories/"
        keywords={[
          'Buy a house in Texas',
          'Sell my home Austin Realtor',
          'Relocate to Austin',
          'Relocation Realtor Austin',
          'Home improvement',
          'DIY projects',
        ]}
      />
      <TaxonomyPageTitle
        leadText="Article and Video Topics"
        subtitle={DESCRIPTION}>
        {TITLE}
      </TaxonomyPageTitle>

      <Item.Group>
        {categories &&
          categories.map(({slug, title, content, imageUrl}) => (
            <Item
              key={slug}
              as={Link}
              to={`/article-categories/${slug}`}
              className="huge-bm">
              {imageUrl && (
                <Item.Image src={imageUrl} alt={`${title} in Austin Texas`} />
              )}
              <Item.Content verticalAlign="middle">
                <Item.Header>{title}</Item.Header>
                <Item.Description>{content}</Item.Description>
              </Item.Content>
            </Item>
          ))}
      </Item.Group>
    </Page>
  );
};

export default ArticleCategoriesPage;
